<template>
  <dash-page-new
      :title="$t('Achievements')"
      :root="$t('Dashboard')"
  >

    <v-row class="mt-10">
      <v-col v-for="(item,i) in items" :key="i" cols="3">
        <div class="d-flex justify-center">
          <v-avatar class="pointer" size="100">
            <v-img
                style="filter: grayscale(100%);"
                :src="item.img" height="100" width="100" />
          </v-avatar>
        </div>


        <h5 class="text-center">{{ item.name }}</h5>
      </v-col>
    </v-row>
  </dash-page-new>
</template>

<script>
import entitiesFunctions from "@/mixins/entitiesFunctions";

export default {
  name: "StudentAchievements",
  mixins : [entitiesFunctions],
  init : 'gamification/GET_STUDENT_ACHIEVEMENTS',
  init_callback(result) {
    this.items = result.items
    this.achievements = result.achievements
  },
  data() {
    return {
      achievements : [],
    }
  }
}
</script>

<style scoped>

</style>